<template>
  <div class="wrap">
    <p class="title">直播列表</p>
    <!-- 搜索 -->
    <a-form layout="inline">
      <a-form-item label="用户名称">
        <a-input v-model:value.trim="query" placeholder="请输入用户名称" size="small" allowClear></a-input>
      </a-form-item>
      <a-form-item label="直播开始时间">
        <a-range-picker size="small" v-model:value="rangeDate" />
      </a-form-item>

      <a-form-item class="btn-group">
        <a-button type="primary" size="small" @click="search">查询</a-button>
        <a-button size="small" @click="reset">重置</a-button>
        <a-button size="small" type="primary" @click="filterRecommend"
          >{{ onlyRecommond ? '取消' : '' }}只看推荐</a-button
        >
      </a-form-item>
    </a-form>
    <!-- 表格 -->
    <a-table
      :dataSource="dataSource"
      :columns="columns"
      :pagination="{ pageSize: pageSize, current: curPage, total: total }"
      :row-key="record => record.id"
      @change="handlePageChange"
    >
      <template #password="row">
        {{ row.record.password ? '是' : '否' }}
      </template>
      <template #status="row">
        <span class="status-tag">{{ row.status === 0 ? '正常' : '暂无' }}</span>
      </template>
      <template #action="row">
        <div class="action">
          <span class="force-close" @click="forceClose(row)">强制关闭</span>
          <span class="edit" @click="showAddEditModal(row)">查看</span>
          <span
            :class="['recommend', row.record.homeRecommend ? 'cancel-recommend' : 'home-recommend']"
            @click="recommend(row)"
          >
            {{ row.record.homeRecommend ? '取消推荐' : '首页推荐' }}
          </span>
        </div>
      </template>
    </a-table>
  </div>
</template>

<script>
import { defineComponent, createVNode, ref, toRefs, reactive, onMounted } from 'vue';
import api from '@/services';
import moment from 'moment';
import { useRouter } from 'vue-router';
import { Modal, message } from 'ant-design-vue';
const columns = [
  {
    title: '直播ID',
    dataIndex: 'id',
    align: 'center',
    ellipsis: true
  },
  {
    title: '用户名称',
    dataIndex: 'nickName',
    align: 'center',
    ellipsis: true
  },
  {
    title: '直播间名称',
    dataIndex: 'roomTitle',
    align: 'center',
    ellipsis: true
  },
  {
    title: '直播开始时间',
    dataIndex: 'lastOnlineTime',
    align: 'center'
  },
  {
    title: '发起端',
    dataIndex: 'liveSource',
    align: 'center'
  },
  {
    title: '是否加密',
    dataIndex: 'password',
    align: 'center',
    ellipsis: true,
    slots: {
      customRender: 'password'
    }
  },
  {
    title: '在线人数',
    dataIndex: 'peopleNum',
    align: 'center',
    ellipsis: true
  },
  {
    title: '操作',
    key: 'action',
    dataIndex: 'action',
    width: 300,
    align: 'center',
    slots: {
      customRender: 'action'
    }
  }
];
export default defineComponent({
  components: {
    // AddEditUserModal,
  },
  setup() {
    onMounted(() => {
      getLiveList();
    });
    const router = useRouter();

    const AddEditUserRef = ref();

    const addEditVisible = ref(false); // 控制 新增 & 编辑 用户弹窗显隐

    // table 相关变量
    const tableState = reactive({
      pageSize: 5,
      curPage: 1,
      total: 0,
      dataSource: [],
      columns
    });

    // 条件筛选表单
    const formState = reactive({
      query: '',
      rangeDate: []
    });

    const onlyRecommond = ref(0);

    // 获取用户列表 table data
    const getLiveList = async () => {
      const params = {
        ...formState,
        startOnlineTime: formState.rangeDate.length
          ? moment(formState.rangeDate[0]).format('YYYY-MM-DD') + ' 00:00:00'
          : '',
        endOnlineTime: formState.rangeDate.length
          ? moment(formState.rangeDate[1]).format('YYYY-MM-DD') + ' 23:59:59'
          : '',
        homeRecommend: onlyRecommond.value ? onlyRecommond.value : '',
        curPage: tableState.curPage,
        pageSize: tableState.pageSize,
        status: 1
      };
      delete params.rangeDate;
      
      console.log(params)
      const { data, success } = await api.getLiveList(params);
      if (success) {
        tableState.dataSource = data.records;
        tableState.total = data.total;
      }
    };

    const handlePageChange = page => {
      tableState.curPage = page.current;
      getLiveList();
    };

    // 查看直播
    const showAddEditModal = e => {
      console.log(e)
      router.push({
        path: '/live-broadcast-watch',
        query: {
          pullFlow: e.record.videoResourcesVO.pullAddressFlv,
          id: e.record.id,
          roomUserId: e.record.roomUserId,
          liveType: e.record.liveType
        }
      });
      // AddEditUserRef.value.showModal(e.record);
    };

    // 强制关闭
    const forceClose = row => {
      Modal.confirm({
        title: '',
        centered: true,
        content: createVNode(
          'div',
          {
            style: 'text-align: center;font-size: 18px;font-weight: bold;'
          },
          '是否强制关闭当前直播间？'
        ),

        async onOk() {
          console.log('rowwwww', row.record.id);
          let { success } = await api.forceCloseLiveRoom({ id: row.record.id });
          if (success) {
            message.success('强制关闭成功！');
            getLiveList();
          }
        }
      });
    };

    // 首页推荐
    const recommend = async row => {
      let { success } = await api.toggleRecommend({ roomId: row.record.id });
      if (success) {
        message.success('操作成功');
        getLiveList();
      }
    };

    const search = () => {
      tableState.curPage = 1;
      getLiveList();
    };

    const reset = () => {
      tableState.curPage = 1;
      Object.keys(formState).map(key => (formState[key] = ''));
      getLiveList();
    };

    // 只看推荐（筛选）
    const filterRecommend = () => {
      onlyRecommond.value = onlyRecommond.value ? 0 : 1;
      search();
    };

    return {
      AddEditUserRef,
      addEditVisible,
      ...toRefs(formState),
      getLiveList,
      handlePageChange,
      showAddEditModal,
      search,
      reset,
      filterRecommend,
      onlyRecommond,
      forceClose,
      recommend,
      ...toRefs(tableState)
    };
  }
});
</script>

<style lang="less" scoped>
.wrap {
  padding: 15px 20px;
  flex-wrap: wrap;
  .title {
    font-weight: bold;
    font-size: 20px;
  }
  :deep(.ant-form) {
    display: flex;
    justify-content: center;
    margin: 15px 0;
    .btn-group {
      flex: 1;
      .ant-btn {
        margin-left: 20px;
      }
    }
  }
  :deep(.ant-table-wrapper) {
    margin-top: 15px;
    .ant-table {
      .avatar {
        width: 50px;
        height: 50px;
        object-fit: cover;
      }
      .status-tag,
      .action > span {
        margin-right: 14px;
        display: inline-block;
        padding: 0px 10px;
        border-radius: 3px;
        font-size: 12px;
        height: 25px;
        line-height: 25px;
      }

      .action {
        span {
          cursor: pointer;
          color: #fff;
        }
        .edit {
          background-color: #007fff;
        }

        .force-close {
          color: #999;
          border: 1px solid #999;
        }
        .home-recommend {
          background-color: #4acc90;
        }
        .cancel-recommend {
          background-color: #faa755;
        }
      }
    }
  }
}
</style>
